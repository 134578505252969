<template>
  <div class="head">
    <div class="head-container">
      <el-row :gutter="24">
        <el-col :span="18">
          <div class="head-left">
            <div class="home">
              <router-link to="/">
                <img
                  class="head-logo"
                  src="@/assets/images/logo-group.png"
                  alt="儿童成长监测"
                >
              </router-link>
            </div>
            <div class="menu">
              <div
                v-for="(item,index) in menus"
                :key="index"
                class="menu-item"
                :class="{active: item.path === $route.path}"
                @click="jump(item)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </el-col>
        <el-col
          :span="6"
          class="head-right"
        >
          <el-dropdown>
            <div class="box">
              <div
                v-if="0"
                class="right-logo"
              >
                <img
                  class="logo-circle"
                  src="@/assets/images/logo-circle.svg"
                  alt=""
                >
              </div>
              <span class="org-name">{{ orgName }}</span>
              <div class="picture">
                <img
                  class="arrow-right"
                  src="@/assets/images/arrow.svg"
                  alt=""
                >
              </div>
            </div>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="logout">
                退出登陆
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
    </div>
    <div class="head-placeholder" />
  </div>
</template>

<script>
import { jumpLogin, getUserInfo } from '@/utils/auth';
import { loginOut } from '@/api/login';

export default {
  data() {
    return {
      menus: [
        {
          name: '机构测评用户',
          path: '/home'
        },
        {
          name: '机构测评激活',
          path: '/user/active'
        }
      ]
    };
  },
  computed: {
    orgName() {
      const userInfo = getUserInfo();
      return userInfo?.orgName;
    }
  },
  methods: {
    logout() {
      const params = {
        phone: localStorage.getItem('phone')
      };
      loginOut(params).finally(() => {
        jumpLogin();
        this.$message.success('已退出登陆！');
      });
    },
    jump(menu) {
      this.$router.push(menu.path);
    }
  },
};
</script>

<style lang="less" scoped>
.head{
  &-container{
    height: 64px;
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background: #fff;
    box-shadow: 0 4px 4px #E3E3E3;
    padding: 0 24px;
    box-sizing: border-box;
    z-index: 99;
  }
  &-placeholder{
    height: 80px;
    visibility: hidden;
  }
  &-left{
    height: 64px;
    display: flex;
  }
  .menu{
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #85C6CE;
    margin-left: 24px;
    &-item{
      padding: 0 47px;
      height: 64px;
      line-height: 64px;
      cursor: pointer;
    }
    .active{
      background: linear-gradient(270deg, #47C2C2 2.64%, #78DBD9 100%);
      color: white;
    }
  }
  &-logo{
    height: auto;
    width: 196px;
    margin-top: 17px;
    margin-bottom: 17px;
    cursor: pointer;
  }
  .picture{
    vertical-align: bottom;
  }
  &-right{
    height: 64px;
    text-align: right;
    .box{
      height: 64px;
      padding-top: 17px;
      cursor: pointer;
      box-sizing: border-box;
      &>*{
        display: inline-block;
      }
    }
    .logo-circle{
      vertical-align: middle;
      margin-right: 8px;
    }
    .org-name{
      font-weight: 500;
      font-size: 14px;
      color: #85C6CE;
    }
    .arrow-right{
      vertical-align: middle;
      margin-left: 28px;
    }
  }
}
</style>
